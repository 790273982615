<template>
  <layout width="420">
    <template slot="header">
      Add geo group
    </template>

    <template>
      <v-form>
        <v-select
          v-if="geoTypes.length > 1"
          v-model="state.geotype"
          :items="geoTypes"
          :loading="fetching"
          item-text="name"
          item-value="id"
          label="Geo group type"
        />
        <v-autocomplete
          v-model="state.geoId"
          :disabled="fetching"
          :items="state.geoObjects"
          item-text="name"
          item-value="id"
          label="Geo group name"
        />
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :disabled="fetching"
        :loading="loading"
        @click.stop="exec"
      >
        Add
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, watch } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { objectService } from '@/modules/objects/api';
import { landmarksService } from '@/modules/landmarks/api';
import { geozonesService } from '@/modules/geozones/api';
import { geotagsService } from '@/modules/geotags/api';

export default {
  name: 'GeoObjectAdd',
  components: {
    Layout
  },
  props: {
    objectId: {
      type: String,
      default: ''
    },
    onlyGeotags: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const geoTypes = props.onlyGeotags
      ? ['geotags']
      : ['landmarks', 'geozones', 'geotags'];
    const state = ref({
      geotype: geoTypes[0],
      geoId: '',
      geoObjects: []
    });
    const fetching = ref(false)

    watch(
      () => state.value.geotype,
      async () => {
        try {
          fetching.value = true
          switch (state.value.geotype) {
            case 'landmarks': {
              state.value.geoObjects = await landmarksService.fetch();
              break;
            }
            case 'geozones': {
              state.value.geoObjects = await geozonesService.fetch();
              break;
            }
            case 'geotags': {
              state.value.geoObjects = await geotagsService.fetch();
              break;
            }
          }
        }
        finally {
          fetching.value = false
        }

        state.value.geoId = '';
      },
      {
        immediate: true
      }
    );

    const submit = async () => {
      await objectService.link(props.objectId, state.value.geoId);
      emit('confirm');
      emit('close');
    };

    const { loading, exec } = usePromise(submit, true);

    return {
      state,
      exec,
      loading,
      geoTypes,
      fetching
    };
  }
};
</script>
